<template>
  <div class="field">
    <b-field :label="secret.label" class="text-no-transform">
      <b-field>
        <p v-if="secret.isHiddenFromDelegates" class="control">
          <b-tooltip
            label="Hidden from delegates"
            position="is-top"
            type="is-warning"
          >
            <button type="button" class="button">
              <b-icon icon="lock" size="is-small" style="color:#ffdd57" />
            </button>
          </b-tooltip>
        </p>

        <b-input
          :key="`${secret._id}-${secret.state}-${viewSecret}`"
          :value="formattedValue"
          type="text"
          readonly
          expanded
        />

        <p v-if="secret.note" class="control is-borderless">
          <b-tooltip label="View note" position="is-top" type="is-info">
            <button class="button has-text-info" @click="viewNote = !viewNote">
              <b-icon icon="info" size="is-small" />
            </button>
          </b-tooltip>
        </p>

        <p class="control">
          <b-tooltip label="Decrypt secret" position="is-top" type="is-dark">
            <button
              :class="{ 'is-loading': secret.state === 'processing' }"
              type="button"
              class="button"
              @click="encodeDecode"
            >
              <b-icon
                :icon="decoded && viewSecret ? 'eye-slash' : 'eye'"
                size="is-small"
              />
            </button>
          </b-tooltip>
        </p>

        <p class="control">
          <b-dropdown :mobile-modal="false" position="is-bottom-left">
            <button slot="trigger" type="button" class="button">
              <b-icon icon="cog" size="is-small" />
            </button>

            <b-dropdown-item @click="$emit('edit')">Edit</b-dropdown-item>

            <b-dropdown-item class="has-text-danger" @click="$emit('remove')"
              >Delete</b-dropdown-item
            >
          </b-dropdown>
        </p>
      </b-field>
    </b-field>

    <b-message v-if="secret.note && viewNote" type="is-info">{{
      secret.note
    }}</b-message>
  </div>
</template>

<script>
export default {
  name: "SecretField",
  props: {
    secret: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      viewSecret: false,
      viewNote: false
    };
  },
  computed: {
    decoded() {
      return this.secret.state === "decoded";
    },
    value() {
      if (this.decoded) {
        return this.secret.value;
      } else {
        const string = this.secret.value.split(":")[1];
        if (!string) return this.secret.value;
        const length = string.length / 2;
        return string.substring(0, length);
      }
    },
    formattedValue() {
      return this.viewSecret && this.decoded
        ? this.value
        : this.toBull(this.value);
    }
  },
  methods: {
    encodeDecode() {
      if (!this.viewSecret) {
        this.$set(this, "viewSecret", true);
        if (!this.decoded) this.$emit("decode");
      } else {
        this.$set(this, "viewSecret", false);
      }
    },
    toBull(string) {
      let result = "";
      for (let index = 0; index < string.length; index++) {
        result += "•";
      }
      return result;
    }
  }
};
</script>

<style lang="scss">
.text-no-transform {
  & > label {
    text-transform: none;
  }
}
</style>
